<template>
    <div
        class="card vh-100p lh-30 cursor-pointer"
        @click="goToDetailPage()"
        v-if="workValidation"
    >
        <v-row v-if="workValidation.realEstate" class="mb-2">
            <v-col>
                <DescriptionItem
                    icon="edt-building"
                    :text="[workValidation.realEstate.name, getBuilding]"
                />
            </v-col>
            <v-col cols="3" class="pl-0 text-right opacity-20">{{ service.utilService.formatStringDateShortHumanReading(workValidation.createdAt) }}</v-col>
        </v-row>
        <DescriptionItem
            icon="edt-localisation"
            :text="[
                workValidation.contact.address,
                workValidation.contact.additionalAddress,
                workValidation.contact.postalCode + ' ' + workValidation.contact.city,
            ]"
        />
        <DescriptionItem icon="edt-info" :text="['N°'+workValidation.number]" />
        <DescriptionItem v-if="this.$store.getters.isManager"
            icon="edt-user"
            :text="[workValidation.serviceProviderCompany.name]"
        />
        <DescriptionItem v-if="this.$store.getters.isProvider"
            icon="edt-user"
            :text="[workValidation.ownerCompany.name]"
        />
        <DescriptionItem
            icon="edt-euro"
            :number="workValidation.price | toCurrency"
        />
        <DescriptionItem v-if="workValidation.requestType"
            icon="edt-info"
            :text="[workValidation.requestType.name]"
        />
        <DescriptionItem v-if="workValidation.businessArea"
            icon="edt-wrench"
            :text="[workValidation.businessArea.name]"
        />
        <!--<DescriptionItem v-if="workValidation.information"
            icon="edt-message"
            :text="[workValidation.information.substr(0, 150)]"
        />-->
        <Frise :workValidation="workValidation" />
    </div>
</template>

<script>
    import Frise from "@/components/requestos/Detail/Frise.vue";
    import DescriptionItem from "@/components/detail/DescriptionItem";
    import workValidationService from "@/plugins/workValidationService";
    import workflowOSService from "@/plugins/workflowOSService";
    import utilService from "@/plugins/utilService";

export default {
  name: "RequestOSItem",
  props: ["workValidation", "index"],
    components: { DescriptionItem, Frise },
  data() {
    return {
      service: {
        workValidationService: new workValidationService(),
        workflowOSService: new workflowOSService(),
        utilService: new utilService(),
      },
    };
  },
  computed: {
    getColorLogo() {
      if (this.workValidation.place == "start") {
        return "grey";
      }
      return "green";
    },
    getStatus() {
      return this.service.workflowService.getStatusFromWorkValidation(
        this.workValidation
      );
    },
    getRole() {
        if (this.$store.getters.isManager) {
            return "manager";
        }
        return "provider";
    },
    getBuilding() {
        var buildingName = "";
        for(var i = 0; i < this.workValidation.buildings.length; i++) {
            if (i > 0) {
                buildingName += ' - ';
            }
            buildingName += this.workValidation.buildings[i].name;
        }
        return buildingName;
    },
  },
  methods: {
    goToDetailPage() {
      this.$router.push({
        name: "RequestOSDetail",
        params: {
          workValidation: this.workValidation,
          id: this.workValidation.id,
        },
      });
    },
    getRequestedByUser() {
      return this.service.workValidationService.getCompleteNameRequesterFromWorkValidation(
        this.workValidation
      );
    },
  },
};
</script>