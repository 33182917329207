<template>
    <v-container fluid class="page-wrapper">
        <v-row class="page-header">
            <v-col cols="12">
                <HeaderSite
                    :title="$t('planning.title')"
                />
            </v-col>
            <v-col cols="12">
                <div class="scrollable pb-4">
                    <v-btn
                        class="filter-btn mr-3"
                        :color="this.selected == 1 ? 'filter-active' : ''"
                        value="1"
                        @click="selectStatus(1)"
                    >
                      {{ $t("planning.all") }}
                    </v-btn>
                    <v-btn
                        class="filter-btn mr-3"
                        :color="this.selected == 2 ? 'filter-active' : ''"
                        value="1"
                        @click="selectStatus(2)"
                    >
                      {{ $t("planning.today") }}
                    </v-btn>
                    <v-btn
                        class="filter-btn mr-3"
                        :color="this.selected == 3 ? 'filter-active' : ''"
                        value="1"
                        @click="selectStatus(3)"
                    >
                      {{ $t("planning.nextWeek") }}
                    </v-btn>
                </div>
            </v-col>
        </v-row>
        <div class="page-content with-filter mt-1" ref="container">
            <v-row ref="containerInner">
                <div v-if="isLoading">
                  <Loader />
                </div>
                <v-col cols="12" class="mb-2 mb-md-5" v-for="rdv in this.$store.getters.StatePlanning" :key="rdv.id" @click="goToIntervention(rdv)">
                    <v-card class="card cursor-pointer" v-if="rdv.status === 2">
                        <v-row>
                            <v-col cols="12" sm="4">
                                <DescriptionItem
                                    additionalClass="mb-2 mb-sm-0"
                                    icon="edt-clock"
                                    :text="[formatStringifyDate(rdv.date), formatStringifyTime(rdv.date)]"
                                />
                            </v-col>
                            <v-col cols="12" sm="4">
                                <DescriptionItem
                                    additionalClass="mb-2 mb-sm-0"
                                    icon="edt-building"
                                    :text="['<b>' + rdv.interventionRequest.realEstate.name + '</b>',
                                            rdv.interventionRequest.realEstate.mainContact.address, 
                                            rdv.interventionRequest.realEstate.mainContact.additionalAddress,
                                            rdv.interventionRequest.realEstate.mainContact.postalCode + ' ' + rdv.interventionRequest.realEstate.mainContact.city]"
                                />
                            </v-col>
                            <v-col cols="12" sm="4">
                                <DescriptionItem
                                    additionalClass="mb-2 mb-sm-0"
                                    icon="edt-info"
                                    :text="[rdv.interventionRequest.number]"
                                />
                                <DescriptionItem v-if="rdv.businessArea"
                                    icon="edt-wrench"
                                    :text="[rdv.businessArea]"
                                />
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </v-container>
</template>
<script>
    import HeaderSite from "@/components/HeaderSite";
    import DescriptionItem from "@/components/detail/DescriptionItem";
    import { mapActions } from "vuex";
    import moment from "moment";
    import businessAreaService from "@/plugins/businessAreaService";
    import Loader from "@/components/Loader";

export default {
  name: "PlanningIndex",
  components: { Loader, HeaderSite, DescriptionItem },
  data() {
    return {
      selected: 1,
      page: 1,
      isLoading: false,
      planning: null,
      minTimeCallPaginateLoading: 3000,
      lastCallPaginateLoading: null,
      service: {
        businessAreaService: new businessAreaService(),
      },
    };
  },
  mounted() {
    this.getAppointments();
    this.getNextAppointments();
  },
  computed: {},
  methods: {
    ...mapActions(["GetPlanning"]),
    selectStatus: function (selected) {
      this.selected = selected;
      this.page = 1;
      this.getAppointments();
    },
    getNextAppointments: function () {
        this.$refs.container.onscroll = () => {
            let container = this.$refs.container;
            let containerInner = this.$refs.containerInner;
            let bottomOfWindow = container.scrollTop + container.offsetHeight > containerInner.offsetHeight - 30;
            let timestamp = Date.now();
            if (bottomOfWindow && (this.lastCallPaginateLoading === null || timestamp - this.lastCallPaginateLoading > this.minTimeCallPaginateLoading)) {
                this.page++;
                this.lastCallPaginateLoading = timestamp;
                this.getAppointments();
            }
        };
    },
    getAppointments: function () {
      let startDate = null;
      let endDate = null;
      moment.locale("fr");
      switch (this.selected) {
        case 1:
          startDate = moment().format("yy-MM-DD");
          break;
        case 2:
          startDate = moment().format("yy-MM-DD");
          endDate = moment().add(1, "day").format("yy-MM-DD");
          break;
        case 3:
          startDate = moment()
            .add(1, "week")
            .startOf("isoWeek")
            .format("yy-MM-DD");
          endDate = moment()
            .add(2, "week")
            .startOf("isoWeek")
            .format("yy-MM-DD");
          break;
      }

      let data = {
        page: this.page,
        numberPerPage: 30,
        startDate: startDate,
        endDate: endDate,
      };
      this.GetPlanning(data);
    },
    formatStringifyDate: function (date) {
      return this.capitalizeFirstLetter(
        moment.utc(date).locale("fr").local().format("DD/MM/yy")
      );
    },
    formatStringifyTime: function (date) {
      return moment.utc(date).locale("fr").local().format("HH:mm");
    },
    capitalizeFirstLetter: function (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    async goToIntervention(rdv) {
      this.isLoading = true;
      this.isLoading = false;
      this.$router.push({
        name: "RequestNominationDetail",
        params: {
          id: rdv.interventionRequest.id,
          defaultTab: "rdv",
        },
      });
    },
  },
};
</script>
