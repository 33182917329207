<template>
    <div
        class="card card-line container-card-dashboard cursor-pointer"
        :class="color"
        @click="goToLink()"
    >
        <v-row class="align-items-center mx-0">
            <v-col cols="3" class="card-nb px-0">
                {{ numberElement }}
            </v-col>
            <v-col cols="9" class="card-text">
                <div class="card-title">
                    {{ $t(text) }}
                </div>
                <div class="card-subtitle">
                    {{ $t(subText) }}
                </div>
            </v-col>
        </v-row>
    </div>
</template>
<script>
    export default {
        name: "DashboardCard",
        props: [
            "text",
            "numberElement",
            "color",
            "subText",
            "link",
            "navigationRoute",
        ],
        data() {
            return {
                number: this.numberElement,
            };
        },
        methods: {
            goToLink() {
                if (this.navigationRoute) {
                    this.$eventBus.$emit("navigate", this.navigationRoute);
                }

                if (this.link) {
                    this.$router.push(this.link);
                }
            },
        },
    };
</script>